.winners-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1800px;
  margin: 0 auto;
}

.youtube-content {
  width: 48.5%;
}

.winners-child-container {
  width: 1100px;
  text-align: center;
}

.youtubeIframe {
  margin: 130px auto 100px;
  width: 100%;
  height: 420px;
  left: 346px;
  top: 202px;
  border: 1px solid #bb87e8;
  box-sizing: border-box;
}

.winners-leaderboard {
  color: #ffffff;
  font-weight: bold;
  margin-top: -62px;
}

.datastudio-content {
  background: linear-gradient(rgba(187, 135, 232, 1), rgba(15, 233, 167, 1));
  margin: 50px auto;
  max-width: 1102px;
  width: 100%;
  height: 826.5px;
  padding: 4px;
}

.dataIframe {
  width: 100%;
  height: 100%;
  left: 145px;
  top: 648px;
  background: rgba(0, 0, 0, 1);
}

.link {
  margin: 50px auto;
  width: 50%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #ffffff;
  border: 1px solid #a9b5c0;
  border-radius: 5px;
  background-color: #ffffff;
  margin-top: 0;
}

.link:hover {
  border: 1px solid rgb(164, 78, 237);
}

.rearities-page-footer {
  max-width: 1050px;
}

.link-container {
  color: #ffffff;
  width: 100%;
  text-align: left;
}

.link-content-container {
  padding: 10px;
}

.link-content {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
}

.content-first-child {
  font-size: 15px;
  color: #000000;
}

.content-second-child {
  font-size: 15px;
  color: #a9b5c0;
}

.medium-icon-container {
  display: flex;
  align-items: center;
}

.medium-icon {
  background-image: url(/src/images/medium_icon.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
}
@media screen and (max-width: 1400px) {
  .youtube-content {
    width: 72% !important;
  }
}

@media screen and (max-width: 1200px) {
  .link {
    max-width: 64.5%;
    font-size: 15px;
  }

  .datastudio-content {
    width: 700px !important;
    height: 525px;
  }
  .rearities-page-footer {
    margin-left: 6%;
  }
}

@media screen and (max-width: 481px) {
  .link {
    max-width: 23.5%;
  }
}

@media screen and (max-width: 800px) {
  .datastudio-content {
    width: 500px !important;
    height: 375px;
  }

  .winners-leaderboard {
    font-size: 11px;
  }
}

@media screen and (max-width: 800px) {
  .link {
    max-width: 27.5%;
  }
}

@media screen and (max-width: 550px) {
  .youtubeIframe {
    height: 250px;
    margin: 100px auto 50px;
    margin-top: 65px;
  }

  .datastudio-content {
    width: 400px !important;
    height: 300px;
  }
}

@media screen and (max-width: 430px) {
  .datastudio-content {
    width: 300px !important;
    height: 225px;
    margin-top: 22px;
  }
  .winners-leaderboard {
    font-size: 8px;
    margin-top: -30px;
  }
}

@media screen and (max-width: 320px) {
  .datastudio-content {
    width: 250px !important;
    height: 188px;
    margin-top: 23px;
  }
  .link {
    max-width: 23.5%;
  }
}

@media screen and (max-width: 900px) {
  .content-first-child {
    font-size: 8px;
  }
  .content-second-child {
    font-size: 8px;
  }
  .link {
    line-height: 15px;
    margin-top: -18px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .header-image-box {
    flex-wrap: wrap;
    min-width: 400px;
  }
  .menu-logo-container {
    min-width: 400px;
  }

  .navbar-text {
    width: 420px;
  }

  .navbar-container {
    margin-right: 0px;
  }
}
