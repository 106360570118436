.rarities-container {
  padding-bottom: 140px;
}

.box {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 55px !important;
}

.attributes-box {
  width: 100%;
  margin-top: 85px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.attributes-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  margin-top: 20px;
  display: block;
  color: #ffff00;
  margin-bottom: 20px;
}

.attributes {
  width: 55%;
}

.attributes-subtitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  display: block;
  color: #ffffff;
  text-align: center;
}

.nav-menu {
  display: flex;
  justify-content: space-around;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: rgba(255, 255, 255, 1);
  width: 59%;
  position: relative;
  bottom: 86px;
  right: 166px;
}

.nav-menu-box > a {
  color: inherit;
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 6px;
  justify-content: center;
  background: -moz-linear-gradient(
    -90deg,
    rgba(0, 128, 255, 1),
    rgba(28, 196, 196, 1)
  );
  background: -webkit-linear-gradient(
    -90deg,
    rgba(0, 128, 255, 1),
    rgba(28, 196, 196, 1)
  );
  border: 1px solid white;
}

.nav-menu-box:hover {
  border: none;
  border-radius: 6px;
  -webkit-box-shadow: 7px 4px 4px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 7px 4px 4px 0 rgba(0, 0, 0, 0.25);
}

a[href="#ninja-suit"],
a[href="#ninja-hair"],
a[href="#ninja-face"] {
  padding: 0px 25px;
}

a[href="#ninja-backgraund"],
a[href="#ninja-weapon"] {
  padding: 0 10px;
}

.rariti-attribute-text-border-2 {
  display: none;
}

.attributes-image {
  width: 284px;
  display: flex;
  justify-content: center;
  position: relative;
  top: 60px;
  border-radius: 6px;
  background: linear-gradient(
    0deg,
    rgba(15, 233, 167, 1) 31%,
    rgba(28, 166, 196, 1) 54%,
    rgba(168, 78, 255, 1) 72%
  );
  padding: 2px;
}

div[data-rmiz-modal-content="true"] {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

div[data-rmiz-modal-content="true"] > img {
  width: 64px;
  height: 64px;
  background: linear-gradient(
    0deg,
    rgba(15, 233, 167, 1) 31%,
    rgba(28, 166, 196, 1) 54%,
    rgba(168, 78, 255, 1) 72%
  );
  padding: 1px;
  border-radius: inherit;
}

.Common-diamond,
.Uncommon-diamond,
.Rare-diamond,
.Epic-diamond {
  position: relative;
  top: 4px;
  margin-right: 5px;
}

.rariti-attribute-cammon > div > span {
  color: rgba(168, 168, 168, 1);
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
}

.rariti-attribute-uncammon > div > span {
  color: rgba(255, 255, 255, 1);
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
}

.rariti-attribute-rare > div > span {
  color: rgba(15, 233, 167, 1);
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
}

.rariti-attribute-epic > div > span {
  background: linear-gradient(
    0deg,
    rgba(255, 199, 0, 1),
    rgba(255, 255, 0, 1),
    rgba(214, 150, 0, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
}

.rariti-attribute-img {
  display: none;
  position: absolute;
}

.rariti-attribute {
  /* position: relative; */
  background-color: #0f0f0f;
  border-radius: inherit;
  width: 100%;
  height: 272px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 40px;
}

.background-box {
  width: 100%;
  margin-top: 8px;
}

.background-title-box {
  border-bottom: 1px solid #a84eff;
  margin: 0 75px;
}

.background-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 12px;
}

.container-box {
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.background-title-box,
.suit-title-box,
.face-title-box,
.hair-title-box,
.weapons-title-box {
  width: 83%;
}

.raritie-box {
  width: 240px;
  height: 215px;
  margin: 22px 54px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.raritie-img {
  width: 200px;
  height: 200px;
}

div[data-rmiz-wrap="visible"] {
  background: linear-gradient(
    0deg,
    rgba(15, 233, 167, 1) 31%,
    rgba(28, 166, 196, 1) 54%,
    rgba(168, 78, 255, 1) 72%
  );
  padding: 2px;
  border-radius: 6px;
}

div[data-rmiz-wrap="visible"] > img {
  border-radius: inherit;
}

.raritie-img > img {
  width: 100%;
  height: 100%;
}

.raritie-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
  margin-left: 6px;
}

.raritie-vector {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.suit-title-box {
  border-bottom: 1px solid #ffffff;
  margin: 0 75px;
}

.rarities-suit-cotainer {
  padding-top: 30px;
}

.rarities-face-cotainer {
  padding-top: 30px;
}

.face-title-box {
  border-bottom: 1px solid #0080ff;
  margin: 0 75px;
}

.rarities-hair-cotainer {
  padding-top: 30px;
}

.hair-title-box {
  border-bottom: 1px solid #a84eff;
  margin: 0 75px;
}

.rarities-weapons-cotainer {
  padding-top: 30px;
}

.weapons-title-box {
  border-bottom: 1px solid #1cc4c4;
  margin: 0 75px;
}

.attributes-res-img {
  display: none;
}

@media all and (min-width: 900px) and (max-width: 1050px) {
  .raritie-box {
    margin-right: -20px;
    margin-left: 10px;
    width: 33%;
  }

  .background-title-box,
  .suit-title-box,
  .face-title-box,
  .hair-title-box,
  .weapons-title-box {
    width: 84% !important;
  }
}

@media screen and (max-width: 650px) {
  .box {
    width: 100% !important;
    padding-top: 30px !important;
  }

  .container-box {
    justify-content: space-between;
  }

  .raritie-img {
    width: 104px !important;
    height: 104px !important;
  }

  .raritie-box {
    margin: 22px 12% 0 !important;
    height: 205px !important;
  }

  .attributes {
    margin-left: 0;
    width: 84%;
  }

  .rarities-container {
    padding-bottom: 80px;
  }

  .raritie-vector {
    width: 26px;
  }

  .raritie-vector > img {
    width: 100%;
  }

  .background-title-box,
  .suit-title-box,
  .face-title-box,
  .hair-title-box,
  .weapons-title-box {
    margin: 0 61px !important;
    width: 76% !important;
  }
}

@media screen and (max-width: 450px) {
  .box {
    width: 100% !important;
  }

  .container-box {
    justify-content: space-between;
  }

  .raritie-box {
    margin: 22px 34px 0 !important;
  }

  .background-title-box,
  .suit-title-box,
  .face-title-box,
  .hair-title-box,
  .weapons-title-box {
    margin: 0 40px !important;
    width: 84% !important;
  }

  .attributes-title {
    width: 87% !important;
  }

  .attributes-subtitle {
    font-size: 14px !important;
  }

  .nav-menu-box {
    border: none;
  }

  .attributes-title {
    width: 87%;
  }
}

@media screen and (max-width: 370px) {
  .raritie-box {
    margin: 22px 21px 0 !important;
  }

  .background-title-box,
  .suit-title-box,
  .face-title-box,
  .hair-title-box,
  .weapons-title-box {
    margin: 0 27px !important;
    width: 87% !important;
  }

  .attributes-title {
    width: 90% !important;
  }
}

@media screen and (max-width: 317px) {
  .container-box {
    justify-content: space-around;
  }

  .background-title-box,
  .suit-title-box,
  .face-title-box,
  .hair-title-box,
  .weapons-title-box {
    width: 76% !important;
  }
}

@media all and (max-width: 900px) {
  a[href="#ninja-suit"],
  a[href="#ninja-hair"],
  a[href="#ninja-face"] {
    padding: 0px;
    width: 67px;
  }

  a[href="#ninja-backgraund"],
  a[href="#ninja-weapon"] {
    padding: 0;
    width: 100px;
    margin-top: 5px;
  }

  .nav-background-box {
    order: 1;
    display: flex;
  }

  .nav-menu > a {
    margin: 6px 2px !important;
  }

  .attributes {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .attributes-img {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .attributes-image {
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.21);
    border-radius: 20px;
    margin-bottom: 30px;
    margin-right: 50px;
    position: relative;
    top: 12px;
  }

  .rariti-attribute {
    background-color: rgba(15, 15, 15, 0.68);
    border-radius: 20px;
    height: 157px;
    justify-content: space-evenly;
    position: relative;
    padding-left: 20px;
  }

  .rariti-attribute-img {
    display: block;
  }

  .rariti-attribute-text-border-2 {
    display: block;
  }

  .rariti-attribute-text-border {
    display: none;
  }

  img[src="/rarities-img/Rectangle 94.png"] {
    bottom: -23px;
    right: -51px;
  }

  img[src="/rarities-img/Rectangle 93.png"] {
    bottom: 54px;
    right: -48px;
  }

  img[src="/rarities-img/Rectangle 92.png"] {
    top: -25px;
    right: 8px;
  }

  .diamond {
    width: 11px;
  }

  .rariti-attribute-text {
    font-size: 12px !important;
  }

  .nav-menu {
    font-size: 11px;
    line-height: 15px;
    bottom: -54px;
    right: 1px;
    width: 215px;
    flex-wrap: wrap;
  }

  .nav-menu > a {
    margin: 0 2px;
  }

  .container-box {
    margin-top: 10px;
  }

  .box {
    width: 670px;
  }

  .background-title-box,
  .suit-title-box,
  .face-title-box,
  .hair-title-box,
  .weapons-title-box {
    margin: 0 99px;
    width: 69%;
  }

  .raritie-box {
    width: 117px;
    height: 235px;
    flex-wrap: wrap;
    margin-right: 8px;
    margin: 22px 100px 0px;
  }

  .raritie-title {
    margin-top: 6px;
    margin-left: 0px;
    text-align: center;
  }

  .background-title {
    font-size: 14px;
    margin-bottom: 0;
  }

  .raritie-title {
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    display: block;
    width: 93%;
  }

  .attributes-title {
    font-size: 14px;
    width: 100%;
  }

  .attributes-res-img {
    width: 270px;
  }

  .raritie-img {
    width: 130px;
    height: 130px;
  }

  .attributes-subtitle {
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    margin-top: 25px;
    text-align: center;
    margin-left: 0;
  }

  .attributes-header-img {
    display: none;
  }

  .attributes-box {
    width: 93%;
    margin: 0 auto 40px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .attributes-res-img {
    display: block;
  }
}
