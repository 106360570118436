p {
  color: white;
  padding: 2px;
}

.mint {
  background: linear-gradient(
    180deg,
    rgba(168, 78, 255, 0.188) 4.17%,
    rgba(0, 128, 255, 0.132) 100%
  );
  padding-bottom: 270px;
  padding-top: 275px;
}

.mint-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mint-info {
  font-size: 1.3em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  text-align: center;
}

.mint-button {
  margin-top: 2em;
  font-weight: bolder;
  font-size: 1.1rem;
  padding: 1.2em 6em;
  cursor: pointer;
  background: linear-gradient(180deg, #0080ff 0%, #1cc4c4 100%);
  border: none;
  box-shadow: 7px 4px 4px rgb(0 0 0 / 25%);

  border-radius: 4px;
  color: #ffffff;
  transition-property: opacity;
  transition-duration: 0.16s;
  transition-timing-function: ease-in;
}

.mint-button:hover {
  opacity: 0.9;
}

@media screen and (max-width: 900px) {
  .mint {
    display: none;
  }
}

.MuiButton-label {
  color: white;
}

.MuiTypography-root {
  color: white !important;
}

.MuiDialogTitle-root {
  color: white !important;
}
