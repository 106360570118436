.our-container {
  padding-bottom: 70px;
  padding-top: 75px;
}

.card-button-img-2 {
  display: none;
}

.card-box {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 93%;
}

.our-title-box {
  width: 50%;
  margin: 0 auto;
}

.our-title {
  font-family: Open Sans;
  display: inline-block;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 65px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-bottom: 70px;
}

.our-title > p {
  display: inline-block;
  background: -webkit-linear-gradient(-90deg, #a84eff 0%, #0fe9a7 82.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-box-img2-text {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.card-box-img {
  width: 230px;
  order: 1;
}
.card-box-img > img {
  width: 100%;
}

.card-box-img2 {
  width: 230px;
  order: 3;
}

.card-box-img2 > img {
  width: 100%;
}

.card-box-text {
  margin-top: 9px;
  width: 204px;
  height: 115px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.card-box-text-right {
  width: 200px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-top: 9px;
  margin-left: 15px;
}

.card-box-text-button {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #ffffff;
  width: 90%;
  margin-left: 16px;
  margin-top: 5px;
}

.card-text-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  width: 75%;
  color: #ffffff;
}

.card-text-click {
  font-weight: bold;
}

.card-text-name-battle {
  color: #ffffff;
  display: inline-block;
  font-weight: normal;
}

.card-box-middle-img {
  order: 2;
  width: 335px;
  margin: 45px 20px 0px 20px;
}

.card-box-middle-img > img {
  width: 100%;
}

.text {
  margin: 20px 0;
}

.card-button {
  order: 4;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-button-img {
  width: 438px;
  display: flex;
  margin: 0px -3px;
  margin-right: 195px;
}

.card-button-img > img {
  width: 100%;
}

.text-button {
  margin-top: 20px;
}

@media screen and (min-width: 2500px) {
  .our-title-box {
    text-align: center;
  }
}

@media screen and (max-width: 1120px) {
  .card-button-img {
    margin-right: 91px !important;
  }
}

@media screen and (max-width: 600px) {
  .our-box {
    width: 84% !important;
  }
}

@media screen and (max-width: 900px) {
  .our-container {
    background: inherit;
  }

  .our-box {
    width: 63%;
    margin: 0 auto;
  }

  .card-button-img-2 {
    display: block;
  }

  .card-button-img-1 {
    display: none;
  }

  .card-box {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .our-title {
    font-size: 26px;
    margin-bottom: 38px;
    margin-top: 14px;
  }

  .card-box-img {
    width: 100%;
    height: 118px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .card-box-img > img {
    width: 114px;
    height: 118px;
  }

  .card-box-text {
    width: 136px;
    margin-left: 17px;
    margin-top: 19px;
    font-size: 10px;
  }

  .card-box-middle-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    margin-left: -5px;
  }

  .card-box-middle-img > img {
    width: 185px;
    height: 165px;
  }

  .card-button-img {
    width: 100%;
    height: 118px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-right: 0 !important;
  }

  .card-button-img > img {
    width: 123px;
    height: 135px;
    order: 3;
  }

  .card-box-text-button {
    width: 140px;
    margin-left: 17px;
    margin-top: 0;
    font-size: 10px;
    line-height: 13px;
  }

  .text-button {
    margin-top: 10px;
  }

  .card-box-text-button > p {
    font-size: 10px;
  }

  .card-box-img2 {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .card-box-img2 > img {
    width: 135px;
    height: 150px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .card-button {
    margin-top: 50px;
  }

  .card-box-img2-text > p {
    width: 139px !important;
    height: 55px;
  }

  .card-box-text-right {
    margin-top: 23px;
    margin-left: 4px;
    font-size: 9.2px;
  }

  .card-box-text-right:nth-child(2) {
    margin-top: 22px;
  }

  .card-text-name-battle {
    font-size: 10px;
  }
  .card-text-click {
    font-size: 10px;
  }
  .card-text-name {
    font-size: 10px;
  }
  .card-box-img2-text {
    margin-top: -24px;
    display: flex;
    row-gap: 1px;
  }
}

@media all and (max-width: 420px) {
  .card-button-img {
    margin-right: 0 !important;
  }
  .our-box {
    width: 74% !important;
  }

  .card-box-text-button {
    margin-right: 5px;
  }
}

@media all and (max-width: 361px) {
  .our-box {
    width: 80% !important;
  }
}

@media all and (max-width: 375px) {
  .our-box {
    width: 80% !important;
  }

  .card-box-img2-text > p {
    width: 100% !important;
    height: 55px;
  }
  .card-box-text-right {
    width: 124px !important;
    margin-top: 29px !important;
  }
  .our-box {
    width: 91% !important;
  }
}
