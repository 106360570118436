.header {
  display: flex;
  justify-content: center;
}

.header-container {
  max-width: 2200px;
  transition: all 1s;
  width: 100%;
  display: grid;
  top: 0;
  grid-template-columns: 2fr 4fr 2fr;
  align-items: center;
  position: fixed;
  z-index: 12;
  background: radial-gradient(34.76% 34.76% at 50% 50%, #000000 100%);
}
.active-nav {
  background: #2b0339;
  opacity: 0.9;
}

.header-logo {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 0.6em 0 0 2em;
}

.header-image {
  margin-right: -170px;
}

.navbar-container {
  margin: 10px 70px 10px 0;
}

.logo-box {
  max-width: 200px;
  height: 55px;
  text-align: center;
  padding: 5px;
  display: flex;
  align-items: center;
}

.logo-box > a > img {
  width: 100%;
  height: 100%;
}

.logo-text {
  color: #ffff00;
  width: 60px;
  height: 45px;
  font-family: Righteous;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin-right: 25px;
}

.navbar-container-box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.winners-title-container {
  z-index: -1;
  position: absolute;
  width: 100%;
  text-align: center;
}

.navbar-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
}

.navbar-text > li > a {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.header-image-box {
  position: relative;
  width: 55.79px;
  height: 55.79px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-rearites-image {
  position: relative;
  width: 55.79px;
  height: 55.79px;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.rarities-winner {
  color: white;
  font-size: 20px;
  margin: 0 10px;
  cursor: default;
}

.logoR-img {
  width: 100%;
}

.logoR-img {
  position: absolute;
  top: 55px;
  transition: all 1s;
  visibility: visible;
  transform: scale(1);
  opacity: 1;
  background: linear-gradient(
    91.77deg,
    #a84eff 31.2%,
    #0fe9a7 60.07%,
    #0dd8b5 88.54%
  );
  width: 110px;
  text-align: center;
  padding: 1px;
  border-radius: 10px;
}

.header-img-text {
  position: absolute;
  top: 55px;
  visibility: hidden;
  transition: all 0.5s;
  opacity: 0.1;
  transform: rotate3d(87, 52, -31, 124deg);
  width: 110px;
  text-align: center;
  padding: 1rem;
  background: linear-gradient(
    91.77deg,
    #a84eff 31.2%,
    #0fe9a7 60.07%,
    #0dd8b5 88.54%
  );
  padding: 1px;
  border-radius: 10px;
}

.module {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 110px;
  height: 25px;
  background: #0f0f0f;
  border-radius: 10px;
  letter-spacing: 1.5px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;
}

.active::after {
  content: "";
  display: block;
  width: 100%;
  height: 6px;
  background-color: #0fe9a7;
  border-radius: 10px;
  transition: all 0.5s;
}

.navbar > a:focus::after {
  transition: all 0.5s;
  visibility: visible;
  width: 0px;
}

.hamburger-menu-box {
  display: none;
}

.menu-box {
  transition: all 0.5s;
  transform: rotate(0deg);
  cursor: pointer;
  width: 70px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu-box-rotate {
  transition: all 0.5s;
  transform: rotate(-54.35deg);
  cursor: pointer;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.row-top {
  transition: all 0.5s;
  width: 12px;
  margin: 2px 0 2px 0;
  border-radius: 10px;
  padding: 2px;
  background: linear-gradient(180deg, #4e93ff 0%, #0fe9a7 42.19%);
}

.row-top-active {
  transition: all 0.5s;
  width: 12px;
  margin: 2px 0 2px 0;
  border-radius: 10px;
  padding: 2px;
  background: linear-gradient(180deg, #4e93ff 0%, #0fe9a7 42.19%);
}

.row-bottom {
  transition: all 0.5s;
  width: 12px;
  margin: 2px 0 2px 0;
  border-radius: 10px;
  padding: 2px;
  background: linear-gradient(180deg, #4e93ff 0%, #0fe9a7 42.19%);
}

.row-bottom-active {
  transition: all 0.5s;
  width: 12px;
  margin: 2px 0 2px 0;
  border-radius: 10px;
  padding: 2px;
  background: linear-gradient(180deg, #4e93ff 0%, #0fe9a7 42.19%);
}

.row-middle {
  transition: all 0.5s;
  width: 12px;
  margin: 2px 0 2px 0;
  border-radius: 10px;
  padding: 2px;
  background: linear-gradient(180deg, #4e93ff 0%, #0fe9a7 42.19%);
}

.row-middle-active {
  transition: all 0.5s;
  width: 24px;
  margin: 2px 0 2px 0;
  border-radius: 10px;
  padding: 2px;
  background: linear-gradient(180deg, #4e93ff 0%, #0fe9a7 42.19%);
}

.menu-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 500px;
}

.disp-none {
  display: none;
}

.rarities-text > p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #0fe9a7;
}

.rarities-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
}

.winners-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
}
.header-image-box {
  min-width: 400px;
}
@media (max-width: 900px) {
  .wallet-container {
    display: none;
  }
  .header-container {
    width: 100%;
  }

  .navbar-container-box {
    background: linear-gradient(
      180deg,
      rgba(15, 233, 167, 0.69) 0%,
      rgba(168, 78, 255, 0.0414) 100%
    );
    border-radius: 20px;
  }

  .navbar {
    margin: 0;
  }

  .hamburger-menu-box {
    display: block;
  }

  .hamburger-media {
    transition: all 0.5s;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 70px;
    left: 100%;
    width: 100%;
  }

  .navbar-active {
    transition: all 0.5s;
    visibility: visible;
    opacity: 1;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    border-radius: 20px;
  }

  .header-logo {
    justify-content: center;
    margin: 0 0 0 0;
  }

  .header-container {
    justify-content: space-between;
  }

  .logo-box {
    width: 55px;
    height: 35px;
  }

  .logo-text {
    width: 40px;
    height: 30px;
    font-size: 12px;
    line-height: 15px;
  }

  .header-image-box {
    width: 38px;
    height: 38px;
  }

  .header-image-box > img {
    width: 100%;
  }

  .navbar-text {
    justify-content: space-around;
    width: 100%;
  }
  .rarities-title {
    font-size: 14px;
    width: 145px;
  }

  .winners-title {
    font-size: 14px;
  }
}

@media (min-width: 901px) {
  .rarities-title {
    width: 55%;
  }
}

@media all and (max-width: 900px) {
  .header-rearites-image {
    width: 38px;
    height: 38px;
    margin-right: 40px;
  }

  .rarities-text > p {
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
  }

  .logoR-img {
    width: 75px;
    top: 35px;
  }

  .module {
    font-size: 10px;
  }

  .header-rearites-image > img {
    width: 100%;
    height: 100%;
  }

  .menu-logo-container {
    min-width: 0;
  }
  .header-container {
    grid-template-columns: 2fr 4fr;
  }

  .menu-logo-container {
    justify-content: flex-end;
    min-width: 100px;
  }

  .header-image-box {
    min-width: 12em;
  }
}

@media only screen and (max-width: 420px) {
  .logoR-img {
    width: 80px;
  }

  .module {
    height: 20px;
    font-size: 10px;
  }

  .navbar-text > li > a {
    font-size: 13px;
  }

  .header-logo {
    width: 120px;
  }
  .header-image-box {
    display: none;
  }
}
