.faq-container {
  padding-top: 120px;
  padding-bottom: 20px;
  background: linear-gradient(
    180deg,
    rgba(0, 128, 255, 0.224) 4.17%,
    rgba(15, 15, 15, 0.272) 100%
  );
}

.faq-box {
  width: 800px;
  margin: 60px auto;
}

.faq-box > div {
  background: transparent;
}

.faq-title-box {
  height: 80px;
  margin-bottom: 83px;
  display: flex;
}

.faq-title > p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #0fe9a7;
  display: flex;
}

.question-list {
  font-family: Open Sans;
  font-size: 28px;
  line-height: 20px;
  border-top: 1px solid #a84eff;
  border-bottom: 1px solid #a84eff;
  padding: 18px 0 18px 0px;

  color: #ffffff !important;
}

.question-icon {
  color: #ffffff !important;
  font-size: 30px;
}

.question-title {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 19px !important;
  line-height: 19px !important;
}

.question-list {
  background: content-box;
  padding: 5px 0 5px 0px;
}

.question-li {
  list-style: disc inside;
  list-style-position: outside;
}

.market-link {
  font-weight: 800;
  color: inherit;
}

.market-link:hover {
  text-decoration: underline;
}

.MuiTypography-body1 {
  line-height: 1.5em !important;
}

@media screen and (max-width: 900px) {
  .faq-container {
    padding-top: 85px;
    /* margin-top: 46px; */
  }

  .question-title {
    font-size: 10px !important;
  }

  .MuiTypography-body1 {
    font-size: 10px !important;
  }

  .question-list {
    padding: 5px 0 5px 0px;
  }

  .faq-box {
    width: 90%;
    margin: 0 auto;
    padding-top: 0px;
  }

  .faq-box > div > div > div > div > p {
    font-size: 10px;
  }

  .faq-title > p {
    font-size: 26px;
    width: 57px;
    height: 37px;
    margin: 0 auto;
  }

  .faq-title-box {
    margin-bottom: 0;
    margin-top: 20px;
  }
}

pre {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
}
