.footer-container {
  width: 800px;
  margin: 0 auto;
  padding-top: 140px;
}

.footer-logo-box {
  max-width: 200px;
  height: 80px;
  min-width: 90px;
  min-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
}

.footer-logo-box > img {
  width: 100%;
  height: 100%;
}

.footer-logo {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer-text {
  max-width: 515px;
  font-family: Roboto;
  font-style: normal;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  margin: 26px 0 22px;
}

.footer-icon {
  display: flex;
  align-items: center;
}

.footer-icon-box {
  margin-left: 20px !important;
  display: flex;
  margin-left: -10px;
  flex-direction: row;
  column-gap: 0;
}

.web-sites {
  cursor: pointer;
}

.rearities-page-footer {
  max-width: 900px;
  margin: 0 auto;
}

.footer-img {
  display: flex;
}

@media screen and (max-width: 900px) {
  .footer-container {
    width: 90%;
    margin: 0 auto;
  }

  .footer-logo {
    width: 155px;
  }

  .footer-icon-box {
    margin-left: 0 !important;
  }

  .footer-text {
    font-size: 13px;
    width: 100%;
    max-width: 335px;
    font-weight: normal;
    line-height: 15px;
  }

  .footer-icon-box > img {
    width: 37px;
    height: 37px;
  }

  .web-sites {
    width: 37px;
  }

  .footer-logo-box {
    width: 35px;
    height: 35px;
  }

  .footer-logo-text {
    font-size: 14px;
    width: 90px;
    font-weight: normal;
    margin: 0px 10px;
  }

  .rearities-page-footer {
    width: 350px;
  }

  .rarities-suit-cotainer {
    padding-top: 10px;
  }

  .rarities-face-cotainer {
    padding-top: 10px;
  }

  .rarities-hair-cotainer {
    padding-top: 10px;
  }

  .rarities-weapons-cotainer {
    padding-top: 10px;
  }
  .footer-logo-img {
    width: 120px;
  }
}

@media screen and (max-width: 370px) {
  .rearities-page-footer {
    width: 92%;
  }
}
