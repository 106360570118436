.main-container {
  background: linear-gradient(
    283deg,
    rgb(0 0 0 / 24%) 4.17%,
    rgb(0 0 0 / 70%) 100%
  );
}

.m-text-span {
  font-weight: bold;
}

.main-box {
  display: flex;
  align-items: center;
  margin-top: 44px;
  margin-right: 80px;
  justify-content: center;
}

.header-image {
  margin-right: -150px;
}

.header-image > img {
  width: 100%;
}

.responcive-img {
  display: none;
}

.title-top {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: -14px;
}

.title-button {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 34px;
  line-height: 46px;
  letter-spacing: 0.03em;
  background: linear-gradient(179.32deg, #a84eff 24.39%, #0fe9a7 58.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 35px;
}

.main-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin: 20px 0;
}

.main-button-container {
  width: 100%;
  height: 38px;
  box-sizing: border-box;
  border-radius: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  column-gap: 15px;
}

.header-text-container {
  width: 425px;
}

.main-button {
  font-family: Righteous;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: #ffffff;
  background: inherit;
  border: none;
  width: 99%;
  border-radius: 10px;
  height: 100%;
  background: linear-gradient(180deg, #0080ff 0%, #1cc4c4 100%);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: inset 7px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .header-text-container {
    text-align: center;
  }

  .main-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 900px) {
  .main-box {
    margin-top: 44px;
    width: 90%;
    margin: 0px auto;
    justify-content: space-around;
    flex-direction: column;
  }

  .main-container {
    width: 100%;
  }

  .header-image {
    margin: 0 auto;
  }

  .header-text-container {
    width: 255px;
    margin: 0 auto;
    margin-top: -160px;
    text-align: center;
  }

  .main-button-container {
    margin: 0 auto;
    width: 211px;
    height: 42px;
  }

  .main-button {
    font-size: 13px;
    line-height: 16px;
  }

  .title-top {
    font-size: 25px;
  }

  .title-button {
    margin-bottom: 15px;
    font-size: 25px;
  }

  .main-text {
    margin: 10px 0;
    font-size: 12px;
  }

  .left-img {
    display: none;
  }

  .responcive-img {
    display: block;
  }

  .title-top {
    font-size: 24px;
  }
  .title-button {
    font-size: 26px;
  }
}
