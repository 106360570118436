.header-date-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.header-box {
  max-width: 2200px;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-image: linear-gradient(to right top, #a84eff, #0fe9a7, #a84eff) 1;
}

.header-date-box {
  display: flex;
  flex-wrap: wrap;
}

.date-container {
  width: 50%;
  max-width: 679px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.01);
  -webkit-backdrop-filter: blur(27px);
  backdrop-filter: blur(27px);
  border-radius: 43px;
  text-align: center;
  border: 2px solid #5a68b8;
  height: 92%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  max-height: 300px;
}

.date-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  margin: 20px auto;
  height: 0;
}

.date-title > span {
  color: #ffff00;
}
.date-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  height: 70%;
}

.date-number {
  font-family: Times New Roman;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 69px;
  color: #ffffff;
}

.date-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.date-stroke {
  font-size: 50px;
  line-height: 69px;
  color: #ffffff;
  margin-bottom: 25px;
}

.result-wrap {
  display: bl;
}

.img-2k,
.img-FHD,
.img-HD {
  display: none;
}

@media screen and (max-width: 1200px) {
  .img-2k {
    display: block;
  }
  .img-4k {
    display: none;
  }
}

@media (max-width: 1024px) {
  .header-date-box {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 360px) {
  .header-date-box {
    flex-flow: wrap;
  }
}

@media screen and (max-width: 900px) {
  .img-2k {
    display: none;
  }

  .img-FHD {
    display: block;
  }

  .header-date-container {
    margin-top: 300px;
  }

  .date-container {
    width: 50%;
  }

  .date-title {
    font-size: 10px;
    line-height: 12px;
    margin: 10px auto;
  }

  .result-wrap {
    display: none;
  }

  .date-box {
    margin: 0 auto;
  }

  .date-text {
    font-size: 10px;
  }

  .date-number {
    font-size: 40px;
  }

  .date-stroke {
    font-size: 30px;
  }
  .date-container {
    width: 281px;
    height: 154px;
  }

  .date-number {
    font-size: 36px;
  }

  .date-stroke {
    font-size: 25px;
  }
}

@media (max-width: 576px) {
  .img-HD {
    display: block;
  }
  .img-FHD {
    display: none;
  }
}
