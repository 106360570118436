.road-container {
  padding-top: 70px;
  padding-bottom: 163px;
  background: linear-gradient(
          180deg, rgba(168, 78, 255, 0.188) 4.17%, rgba(0, 128, 255, 0.132) 100% );
}

.road-box {
  display: flex;
  justify-content: center;
}

.road-title {
  width: 50%;
  margin: 0 auto;
}

.road-title-text {
  font-family: "Open Sans";
  display: inline-block;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 65px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-bottom: 70px;
}

.road-img-box {
  position: relative;
}

.road-title-text > p {
  display: inline-block;
  background: -webkit-linear-gradient(-90deg, #a84eff 0%, #0fe9a7 82.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.road-line-box {
  margin: 0 60px;
  position: relative;
}

.road-box-text {
  position: relative;
}

.road-text {
  width: 360px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
}

@media screen and (min-width: 2500px) {
  .road-title {
    text-align: center;
  }
}

.fire-shuricen {
  position: absolute;
  top: -60px;
  left: -102px;
}

.shuricen {
  position: absolute;
  top: 563px;
  right: -95px;
}

.img1 {
  position: absolute;
  top: 21px;
  right: 190px;
}

.img2 {
  position: absolute;
  top: 177px;
  left: 190px;
}

.img3 {
  position: absolute;
  top: 329px;
  right: 190px;
}

.img4 {
  position: absolute;
  top: 482px;
  left: 190px;
}

.text {
  width: 190px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  letter-spacing: 0.04em;
  color: #ffffff;
}

.text-date {
  margin-bottom: 20px;
}

.text-1 {
  position: absolute;
  top: 32px;
  right: -45px;
}

.text-2 {
  position: absolute;
  top: 196px;
  left: -45px;
}

.text-3 {
  position: absolute;
  top: 345px;
  right: -45px;
}

.text-4 {
  position: absolute;
  top: 510px;
  left: -45px;
}

.roadmap-footer {
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  flex-direction: column;
  color: #ffffff;
  margin-bottom: 20px;
}

.bottom-text > p {
  margin: 0 0 15px;
}

.road-footer-img {
  position: relative;
  width: 711px;
  display: flex;
  justify-content: space-between;
}

.road-footer-img > img {
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .text > p {
    line-height: 14px;
  }

  .road-title {
    text-align: center;
  }

  .road-title-text {
    font-size: 26px;
  }

  .vector-img > img {
    width: 100%;
  }

  .img1 > img {
    width: 100%;
  }

  .img2 > img {
    width: 100%;
  }

  .img3 > img {
    width: 100%;
  }

  .img4 > img {
    width: 100%;
  }

  .fire-shuricen > img,
  .shuricen > img {
    width: 100%;
  }

  .vector-img {
    margin: 0 auto;
    width: 500px;
  }

  .img1,
  .img2,
  .img3,
  .img4 {
    width: 120px;
  }

  .img1 {
    top: 12px;
    right: 123px;
  }

  .img2 {
    top: 117px;
    left: 123px;
  }

  .img3 {
    top: 218px;
    right: 123px;
  }

  .img4 {
    top: 323px;
    left: 123px;
  }

  .fire-shuricen {
    width: 140px;
    top: -51px;
    left: -19px;
  }

  .shuricen {
    width: 160px;
    top: 366px;
    right: -43px;
  }

  .text {
    width: 157px;
    font-size: 10px;
  }

  .text-1 {
    top: 12px;
    right: -54px;
  }

  .text-2 {
    top: 132px;
    left: -32px;
  }

  .text-3 {
    top: 222px;
    right: -54px;
  }

  .text-4 {
    top: 338px;
    left: -40px;
  }

  .bottom-text {
    font-size: 14px;
  }

  .road-footer-img > img {
    width: 150px;
    height: 30px;
  }

  .road-footer-img {
    width: 534px;
  }
}

@media screen and (max-width: 600px) {
  .bottom-text {
    font-size: 12px;
    width: 290px;
  }

  .road-footer-img > img {
    width: 89px;
    height: 22px;
  }

  .text {
    width: 145px;
  }

  .road-footer-img {
    width: 313px;
  }

  .fire-shuricen {
    width: 70px;
    top: -27px;
    left: -17px;
  }

  .text-date {
    margin-bottom: 10px;
  }

  .text-1 {
    top: 4px;
    left: 94px;
  }

  .text-2 {
    top: 128px;
    left: 246px;
  }

  .text-3 {
    top: 229px;
    left: 94px;
  }

  .text-4 {
    top: 333px;
    left: 246px;
  }

  .shuricen {
    width: 82px;
    top: 403px;
    right: -13px;
  }
}
@media screen and (min-width: 370px) and (max-width: 500px) {
  .shuricen {
    /* width: 107px;*/
    top: 399px;
    right: 43px;
  }
  .road-container {
    width: 400px;
    overflow: hidden;
    margin: 70px auto 0;
  }
  .fire-shuricen {
    left: 43px;
    top: -28px;
  }
}

@media screen and (max-width: 400px) {
  .shuricen {
    top: 403px;
    right: 65px;
    transform: rotate(75deg);
  }
}

@media screen and (max-width: 370px) {
  .vector-img {
    width: 400px;
  }
  .img1,
  .img2,
  .img3,
  .img4 {
    width: 100px;
  }
  .img1 {
    top: 8px;
    right: 100px;
  }

  .img2 {
    top: 92px;
    left: 100px;
  }
  .img3 {
    top: 172px;
    right: 100px;
  }
  .img4 {
    top: 254px;
    left: 100px;
  }

  .text-date {
    margin-bottom: 0;
  }

  .text-1 {
    top: 8px;
    left: 69px;
  }
  .text-2 {
    top: 99px;
    left: 202px;
  }
  .text-3 {
    top: 188px;
    left: 92px;
  }
  .text-4 {
    top: 263px;
    left: 202px;
  }

  .fire-shuricen {
    left: 19px;
  }

  .shuricen {
    transform: rotate(30deg);
    width: 70px;
    top: 328px;
    right: 10px;
  }
}
